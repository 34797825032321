// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-list-js": () => import("./../src/components/blog-list.js" /* webpackChunkName: "component---src-components-blog-list-js" */),
  "component---src-components-blog-post-js": () => import("./../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-projects-js": () => import("./../src/pages/open-source/projects.js" /* webpackChunkName: "component---src-pages-open-source-projects-js" */)
}

